input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  background: rgb(230, 230, 230);
  height: 2px;
}

input[type='range']::-webkit-slider-thumb {
  background-image: url('/images/escapod-range-slider-icon.svg');
  -webkit-appearance: none;
  height: 22px;
  width: 32px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin-top: -10px;
}

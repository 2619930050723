@import 'react-datepicker/dist/react-datepicker.css';
@import 'slick-carousel/slick/slick.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts';
@import 'inputs';

* {
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Escapod Grotesk Regular';
  src: url('/fonts/EscapodGroteskRegular.eot');
  src: url('/fonts/EscapodGroteskRegular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/EscapodGroteskRegular.woff') format('woff'),
    url('/fonts/EscapodGroteskRegular.ttf') format('truetype'),
    url('/fonts/EscapodGroteskRegular.svg#EscapodGroteskRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Escapod Grotesk News';
  src: url('/fonts/EscapodGroteskNews.eot');
  src: url('/fonts/EscapodGroteskNews.eot?#iefix') format('embedded-opentype'),
    url('/fonts/EscapodGroteskNews.woff') format('woff'),
    url('/fonts/EscapodGroteskNews.ttf') format('truetype'),
    url('/fonts/EscapodGroteskNews.svg#EscapodGroteskNews') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Escapod Grotesk Sub-Headline';
  src: url('/fonts/Escapod Grotesk Sub-Headline.eot');
  src: url('/fonts/Escapod Grotesk Sub-Headline.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Escapod Grotesk Sub-Headline.woff') format('woff'),
    url('/fonts/Escapod Grotesk Sub-Headline.ttf') format('truetype'),
    url('/fonts/Escapod Grotesk Sub-Headline.svg#Escapod Grotesk Sub-Headline') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Escapod Grotesk Sub-Headline-News';
  src: url('/fonts/Escapod Grotesk Sub-Headline-News.eot');
  src: url('/fonts/Escapod Grotesk Sub-Headline-News.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Escapod Grotesk Sub-Headline-News.woff') format('woff'),
    url('/fonts/Escapod Grotesk Sub-Headline-News.ttf') format('truetype'),
    url('/fonts/Escapod Grotesk Sub-Headline-News.svg#Escapod Grotesk Sub-Headline-News')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Escapod Grotesk Headline';
  src: url('/fonts/Escapod Grotesk Headline.eot');
  src: url('/fonts/Escapod Grotesk Headline.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Escapod Grotesk Headline.woff') format('woff'),
    url('/fonts/Escapod Grotesk Headline.ttf') format('truetype'),
    url('/fonts/Escapod Grotesk Headline.svg#Escapod Grotesk Headline') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Escapod Grotesk Headline-News';
  src: url('/fonts/Escapod Grotesk Headline-News.eot');
  src: url('/fonts/Escapod Grotesk Headline-News.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Escapod Grotesk Headline-News.woff') format('woff'),
    url('/fonts/Escapod Grotesk Headline-News.ttf') format('truetype'),
    url('/fonts/Escapod Grotesk Headline-News.svg#Escapod Grotesk Headline-News') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Escapod Grotesk Extra Bold';
  src: url('/fonts/Escapod Grotesk Extra Bold.eot');
  src: url('/fonts/Escapod Grotesk Extra Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Escapod Grotesk Extra Bold.woff') format('woff'),
    url('/fonts/Escapod Grotesk Extra Bold.ttf') format('truetype'),
    url('/fonts/Escapod Grotesk Extra Bold.svg#Escapod Grotesk Extra Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Italic';
  src: url('/fonts/NotoSans-Italic.eot');
  src: url('/fonts/NotoSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NotoSans-Italic.woff') format('woff'),
    url('/fonts/NotoSans-Italic.ttf') format('truetype'),
    url('/fonts/NotoSans-Italic.svg#NotoSans-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Work Sans Medium';
  src: url('/fonts/WorkSans-Medium.eot');
  src: url('/fonts/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/WorkSans-Medium.woff') format('woff'),
    url('/fonts/WorkSans-Medium.ttf') format('truetype'),
    url('/fonts/WorkSans-Medium.svg#WorkSans-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
}
